// General

a,
button,
.btn {
  outline: none !important;
}

.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
}

.editorDefault {
  font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
  font-size: 14px;
  color: black;
  line-height: 1.5;

}

.ck.ck-editor__editable_inline {
  padding:0 !important;
  border: none !important;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable){
  box-shadow: none !important;
}
